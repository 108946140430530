import React, { useState, useEffect } from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";
import Layout from "../../../components/chat-channel/Layout";
import Container from "../../../components/chat-channel/Container";
import TitleAndMetaTags from "../../../components/common/TitleAndHeader";
import { RequestForm } from "../../../components/form";
import { GoToPopup } from "../../homepage";
import { CookiesPoup } from "../../homepage";
import { useMedia } from "use-media";
import { BlogCta } from "./helpdesk-problems-solutions";
import "react-accessible-accordion/dist/fancy-example.css";

import "react-alice-carousel/lib/alice-carousel.css";
import "react-accessible-accordion/dist/fancy-example.css";
import { CaseStudyCtaGoTo } from "./generative-ai-itsm";

const topImage = require("../../../assets/images/market_ui/slack/blog_image.png");
const header_image = require("../../../assets/img/blogs/gabaei_blog_header.png");
const header_image_mobile = require("../../../assets/img/blogs/gabaei_blog_header_mob.png");
const section_1 = require("../../../assets/img/blogs/gabaei_blog_image_1.png");
const section_2 = require("../../../assets/img/blogs/gabaei_blog_image_2.png");
const section_3 = require("../../../assets/img/blogs/gabaei_blog_image_3.png");
const section_4 = require("../../../assets/img/blogs/gabaei_blog_image_4.png");
const section_5 = require("../../../assets/img/blogs/gabaei_blog_image_5.png");
const section_6 = require("../../../assets/img/blogs/gabaei_blog_image_6.png");
const section_7 = require("../../../assets/img/blogs/gabaei_blog_image_7.png");
const section_8 = require("../../../assets/img/blogs/gabaei_blog_image_8.png");
const section_9 = require("../../../assets/img/blogs/gabaei_blog_image_9.png");
const section_10 = require("../../../assets/img/blogs/gabaei_blog_image_10.png");
const section_11 = require("../../../assets/img/blogs/gabaei_blog_image_11.png");
const section_12 = require("../../../assets/img/blogs/gabaei_blog_image_12.png");
const section_13 = require("../../../assets/img/blogs/gabaei_blog_image_13.png");
const cta_1 = require("../../../assets/img/blogs/tsvg_cta_1.png");
const cta_2 = require("../../../assets/img/blogs/tsvg_cta_2.png");
const cta_1_mob = require("../../../assets/img/blogs/tsvg_cta_1_mob.png");
const cta_2_mob = require("../../../assets/img/blogs/tsvg_cta_2_mob.png");
const arrow_open = require("../../../assets/images/testimonials/arrow_open_blog.svg");
const arrow_close = require("../../../assets/images/testimonials/arrow_close_blog.svg");
const author = require("../../../assets/img/blogs/deepa_2.png");
const author_mobile = require("../../../assets/img/blogs/mobile_deepa_2.png");

const ldSchema = {
  "@context": "https://schema.org",
  "@type": "BlogPosting",
  mainEntityOfPage: {
    "@type": "WebPage",
    "@id":
      "https://workativ.com/conversational-ai-platform/slack-chatbot-guide",
  },
  headline: "Slack Chatbot Guide for your Business",
  image: "",
  author: {
    "@type": "Organization",
    name: "Team Workativ",
  },
  publisher: {
    "@type": "Organization",
    name: "Workativ",
    logo: {
      "@type": "ImageObject",
      url:
        "https://workativ.com/static/workativ-logo-5eea21f2dcd20bbbbf52debb41c0f26e.png",
    },
  },
  datePublished: "2021-08-05",
  dateModified: "2021-08-05",
};

export default function FeatureHomePage() {
  const isMobile = useMedia({ maxWidth: "520px" });
  const isSmall = useMedia({ maxWidth: "520px" });
  const backgroundImage = isSmall ? header_image_mobile : header_image;
  return (
    <>
      <TitleAndMetaTags
        title=" Let the GenAI Bot Handle It: Automating Common Employee IT Issues"
        description="GenAI chatbot efficiently automates common employee IT issues. Explore the use cases and bring service desk efficiency with minimal effort with the SaaS-based Workativ GenAI bot."
        keywords={[
          "ai for itsm, ai itsm, itsm, itsm ai, ai in itsm, ai service management, artificial intelligence service management",
        ]}
        ogImage={header_image}
        schema={ldSchema}
      />

      <Container>
        <Layout backgroundColor={"roi_bg"} logoFor="ASSISTANT">
          <section
            className={`w-100 float-left blog_top_header_upt striking_blog_header_upt ${
              isSmall ? "align-items-start" : null
            }`}
            style={{
              backgroundImage: `url(${backgroundImage})`,
            }}
          >
            <div className="container">
              <div
                className={`flex_cont_top_blog_img  ${
                  isMobile ? "text-align-center" : "text-align-left"
                }`}
              >
                {" "}
                {isMobile ? (
                  <h1 className="font-page-header-home-blog color-black">
                    Let the GenAI Bot Handle It: Automating Common Employee IT
                    Issues
                  </h1>
                ) : (
                  <h1 className="font-page-header-home-blog-new color-black">
                    Let the GenAI Bot
                    <br /> Handle It: Automating
                    <br /> Common Employee
                    <br /> IT Issues
                  </h1>
                )}
              </div>
            </div>
          </section>
          {isMobile ? <ContentContainerMobile /> : <ContentContainer />}
          {isMobile ? <RelatedBlogs /> : null}
          <RequestForm isFooterForm={true} />
        </Layout>
      </Container>
    </>
  );
}
function ContentContainer() {
  const [activeLink, setActiveLink] = useState("");
  const handleClick = (section) => {
    setActiveLink(section);
  };

  return (
    <section className="w-100 float-left margin-bottom-40 blog_content_parent_container">
      <div className="container">
        <div className="grid_container_goto_second grid_container_blogs_content">
          <div className="grid-left-container-goto blogs_grid_left_container w-100">
            <div className="sticky_container_goto_case">
              <h2 className="font-section-sub-header-small text-decoration-none">
                Table of Contents
              </h2>
              <hr className="underline_blogs_toc mt-0 mb-4"></hr>
              <div className="redirection-flex-container-blog">
                <AnchorLink
                  offset={180}
                  href="#section1"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section1"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section1")}
                >
                  1. What is a GenAI chatbot for your employee support?
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section2"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section2"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section2")}
                >
                  2. Why is a traditional service desk a pain for everyone?
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section3"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section3"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section3")}
                >
                  3. How does a GenAI chatbot address existing service desk
                  challenges for employee support?
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section4"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section4"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section4")}
                >
                  4. What Common IT use cases can you implement with a GenAI
                  chatbot?
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section5"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section5"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section5")}
                >
                  5. Benefits of GenAI bot for your employee support
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section6"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section6"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section6")}
                >
                  6. Boost user experience for IT support with the Workativ
                  GenAI chatbot
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section7"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section7"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section7")}
                >
                  7. FAQs
                </AnchorLink>
              </div>
            </div>
          </div>
          <div className="grid-right-container-goto">
            <div className="divs_blogs_upt">
              <p className="font-section-normal-text-testimonials line-height-18">
                Companies see great convenience in using chatbots for repetitive
                tasks around employee support.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                However, the problem arises when chatbots exhibit limited
                capacity to handle even common employee IT conversations and
                offer very little help for real-time solutions to employees.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Enter the GenAI chatbot. GenAI or LLM-powered solutions are
                making waves for their exceptional ability to{" "}
                <span className="font-section-normal-text-testimonials-medium color-black">
                  boost employee productivity.
                </span>
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                In its article, the{" "}
                <a href="https://www.cio.com/article/1312721/3-areas-where-gen-ai-improves-productivity-until-its-limits-are-exceeded.html">
                  CIO
                </a>{" "}
                said that EY heavily uses GenAI to automate internal tasks for
                its 40,000-plus employees, and they see a huge ROI on their AI
                investments.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Best known for creating new content, processing massive amounts
                of data, and producing human-like responses, the best
                application of GenAI is to implement its properties to
                complement chatbots.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                The LLM revolution helps democratize AI for everyone. SMBs can
                get an edge and take employee support to the next level with
                GenAI chatbots.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Lest you stress over how to handle common employee IT issues,
                let GenAI bot intervene.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section1">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                What is a GenAI chatbot for your employee support?
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18">
                A GenAI chatbot is an employee support chatbot that employs
                GenAI properties to automate communications and collaborations,
                automate information delivery for problem resolutions of common
                and cross-functional issues, improve agents’ efficiency, and
                enhance user experience.
              </p>
              <h3 className="font-section-sub-header-small">
                Onboarding Experience: Traditional vs. GenAI Chatbot
              </h3>
              <img
                src={section_1}
                className="blog_image_top_bt"
                alt="Generative AI chatbot vs. traditional chatbots for onboarding experience"
              />
              <p className="font-section-normal-text-testimonials line-height-18">
                For example, a new hiring onboarding process is underway. You
                know the internal tasks for HR departments and their related
                cross-functional operations.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                A new hire onboarding process includes completing paperwork,
                setting up a user email, adding a user to the internal
                communications channel, allowing provisions for company-wide
                tools, etc.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                These are common yet repetitive tasks for both IT and HR teams.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                GenAI chatbot uses large language models and automates these
                tasks easily, which helps make the user experience amazing and
                unforgettable.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section2">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                Why is a traditional service desk a pain for everyone?
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18">
                A service desk is always challenging for agents, especially when
                you fear agent burnout.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                A service desk is often not modern, meaning it still has a
                typical chatbot for employees to address their day-to-day
                issues.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                As a result, it triggers many unforeseen problems for employees,
                agents, stakeholders, etc. Eventually, this costs companies lost
                productivity, a slump in profit, and reduced user experience.
              </p>
              <img
                src={section_2}
                className="blog_image_top_bt"
                alt="Generative AI automation to remove IT service desk challenges"
              />
              <p className="font-section-normal-text-testimonials line-height-18">
                Some glaring problems are—
              </p>
              <ul className="float-left w-100 blogs_ul_upd pl-4 mb-1">
                <li className="font-section-sub-header-small color-black">
                  Repetitive issues
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18 pl-5">
                <a href="https://workativ.com/conversational-ai-platform/blog/reset-password-requests-5-star-rating-with-workativ-assistant">
                  Password resets,
                </a>{" "}
                account unlocks, and software installs are common yet repetitive
                problems. Limited automation for knowledge discovery forces
                employees to escalate tickets to agents.
              </p>
              <ul className="float-left w-100 blogs_ul_upd pl-4 mb-1">
                <li className="font-section-sub-header-small color-black">
                  Rising ticket volumes
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18 pl-5">
                The number of tickets depends on the number of tools a business
                uses. On average, a service desk may receive 20-25 tickets, and
                a ticket may take a few hours to resolve, while a complex may
                take a few days for resolution.
              </p>
              <ul className="float-left w-100 blogs_ul_upd pl-4 mb-1">
                <li className="font-section-sub-header-small color-black">
                  Fragmented system for service desk management
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18 pl-5">
                Agents become frustrated as they need to toggle between emails,
                voice calls, and chats to trace tickets and provide a solution.
                The unresolved tickets add to the queue, resulting in a ticket
                backlog.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section3">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                How does a GenAI chatbot address existing service desk
                challenges for employee support?
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18">
                Generative AI trains on massive datasets and thousands of
                examples of real-world functions, enabling the AI model to build
                semantic and intent search abilities.
              </p>
              <img
                src={section_3}
                className="blog_image_top_bt"
                alt=" GenAI bot for service desk automation"
              />
              <p className="font-section-normal-text-testimonials line-height-18">
                Below are the expected possibilities with GenAI models—
              </p>
              <ul className="float-left w-100 blogs_ul_upd font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  Learning new content using existing examples
                </li>
                <li className="font-section-normal-text-testimonials">
                  Adaptive learning capacity with changing scenarios
                </li>
                <li className="font-section-normal-text-testimonials">
                  Suggesting contextual answers
                </li>
                <li className="font-section-normal-text-testimonials">
                  Less probability of being overwhelmed while interacting with
                  massive datasets
                </li>
              </ul>

              <p className="font-section-normal-text-testimonials line-height-18">
                With these abilities, GenAI solutions stay ahead of
                keyword-based chatbots and act more like{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/ai-co-pilot-it-operations">
                  AI copilots for IT leaders,
                </a>{" "}
                service desk agents, and users.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Let’s see how GenAI chatbots work better than regular chatbots:
              </p>
              <img
                src={section_4}
                className="blog_image_top_bt"
                alt="GenAI bot vs. typical chatbots for service desk automation"
              />
              <h3 className="font-section-sub-header-small">
                Custom responses
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                Typical chatbots: Any search inputs work by matching queries
                with keywords or existing pre-defined chat templates or option
                cards. Employees who want answers outside the predefined
                structures can only expect rudimentary answers.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                GenAI chatbots: LLMs help GenAI chatbots run semantic or
                intent-based search functions. Whether a question is prepared
                for service desk scenarios, the GenAI chatbot handles it
                intelligently and escalates it to an agent if no proper response
                is provided.
              </p>
              <h3 className="font-section-sub-header-small">User adoption</h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                Typical chatbots: Users usually avoid using typical chatbots as
                they often find them unresponsive or difficult to use. They
                prefer using emails, phones, chats, and even personal devices to
                communicate their IT issues.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                GenAI chatbots: Knowledge articles or third-party company data
                easily provide rich resources for its LLM databases. Users can
                easily find what they need to work. This improves adoption and
                problem resolution.
              </p>
              <h3 className="font-section-sub-header-small">
                Reduced ticket counts
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                Typical chatbots: More than often, knowledge articles remain
                static, offering outdated information. FAQs are also limited.
                This leads employees to seek help from service desk agents,
                increasing the ticket numbers.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                GenAI chatbots: LLMs make it easy to keep knowledge bases
                updated with their inherent capacity to learn new things.
                Without the need to add new scenarios to the KBs, GenAI chatbots
                can help employees solve common problems with updated
                information, reducing ticket escalation.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Thus, A GenAI chatbot can provide several and many more useful
                use cases for your employee support and make your IT support
                efficient and effective, ensuring business resilience and
                operations efficiency.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section4">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                What Common IT use cases can you implement with a GenAI chatbot?
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18">
                GenAI chatbots can easily turn into an AI copilot. As their
                assistants, it can help your employees handle repetitive tasks
                and reduce their workload. GenAI chatbot or AI copilot makes
                performing a task easier, which increases their productivity in
                many ways.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                GenAI chatbot use cases for common IT employee issues—
              </p>
              <h3 className="font-section-sub-header-small">
                1. Reminder for maintenance work
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                One important IT task is maintaining company applications. By
                creating a prompt or GenAI workflow for conversational AI, your
                IT employees can do their jobs and never miss important work to
                maintain operational efficiency.
              </p>
              <img
                src={section_5}
                className="blog_image_top_bt"
                alt="GenAI bot for non-stop productivity"
              />
              <h3 className="font-section-sub-header-small">
                2. Request triage
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                It is usual to have hundreds of common IT queries for your
                employees. But, service desk agents struggle to decide who needs
                to take care of a particular issue. GenAI chatbot can
                efficiently solve common queries and detect when a request needs
                expert help. It can instantly route the request to the right
                team.
              </p>
              <img
                src={section_6}
                className="blog_image_top_bt"
                alt="GenAI bot for triage automation"
              />
              <h3 className="font-section-sub-header-small">
                3. Troubleshoot common IT issues
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                In most scenarios, employees do not get their IT questions
                answered. LLMs help turn knowledge into ChatGPT-like responses
                for user queries and troubleshoot common IT issues.
              </p>
              <img
                src={section_7}
                className="blog_image_top_bt"
                alt="GenAI bot for troubleshooting common issues"
              />
              <h3 className="font-section-sub-header-small">
                4. Check password expiration
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                Password expiration can impact employees' productivity if not
                done on time. By creating an automated workflow for password
                expiration, you can allow your employees to quickly get the
                alert and create new passwords for continuous access.
              </p>
              <img
                src={section_8}
                className="blog_image_top_bt"
                alt="GenAI bot for notification automation for password reset"
              />
              <h3 className="font-section-sub-header-small">
                5. Update profile information
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                Your employees must have access to company-wide applications.
                GenAI bot can use workflows that allow your employees to provide
                their information and keep having access to them.
              </p>
              <img
                src={section_9}
                className="blog_image_top_bt"
                alt="GenAI bot for profile updates"
              />
              <h3 className="font-section-sub-header-small">
                6. Request app removal
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                You can send a workflow to remove apps that are no longer
                needed. Users can easily fetch the necessary information from a
                GenAI chatbot and help your employees remove an app.
              </p>
              <h3 className="font-section-sub-header-small">
                7. Account unlock
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                Employees getting locked out of key applications is common and
                creates productivity issues. GenAI helps you create many options
                for your employees to try and get access to accounts.
              </p>
              <img
                src={section_10}
                className="blog_image_top_bt"
                alt="GenAI bot for account unlock automation"
              />
              <h3 className="font-section-sub-header-small">
                8. Software provision
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                You don’t know when your people need a software suite to
                complete their work. Instead of chasing teammates to learn how
                to request a tool or connect with the IT team, your employees
                can access the necessary information and get help instantly.
              </p>
              <img
                src={section_11}
                className="blog_image_top_bt"
                alt="GenAI bot for automating software provisions"
              />
              <h3 className="font-section-sub-header-small">
                9. Password resets
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                Employees often face account access issues and need help with
                ‘reset passwords’ requests. GenAI chatbot dramatically reduces
                ticket escalation to service desk agents and offers real-time
                help to reset passwords.
              </p>
              <img
                src={section_12}
                className="blog_image_top_bt"
                alt="GenAI bot for password reset automation"
              />
              <h3 className="font-section-sub-header-small">
                10. Approval management
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                Most applications are not easily accessible, and your employees
                need manager approvals. GenAI bots can surface workflows that
                allow for easy approvals and increase productivity.
              </p>
              <img
                src={section_13}
                className="blog_image_top_bt"
                alt="GenAI bot for approval management automation"
              />
              <BlogCta
                ContentCta="Optimize Your IT Service Management with AI."
                ButtonText="Book a Demo"
                isColor="white"
                backgroundImage={cta_2}
                mobileBackgroundImage={cta_2_mob}
              />
            </div>
            <div className="divs_blogs_upt float-left" id="section5">
              <h2 class="font-section-sub-header-blog color-heading-blogs">
                Benefits of GenAI bot for your employee support
              </h2>
              <p class="font-section-normal-text-testimonials line-height-18">
                When you leverage GenAI for your conversational AI chatbots, you
                can create an exceptional user experience by enabling them to do
                their best.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                Below are some essential GenAI chatbot benefits for your
                employee support—
              </p>
              <ul className="float-left w-100 blogs_ul_upd pl-4 mb-1">
                <li className="font-section-sub-header-small color-black">
                  Service desk efficiency
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18 pl-5">
                Service desks have increased satisfaction for increased
                efficiency.
              </p>
              <ul className="float-left w-100 blogs_ul_upd pl-6 list-style-type-none">
                <li className="font-section-normal-text-testimonials">
                  - Users can get instant solutions for common IT issues.
                </li>
                <li className="font-section-normal-text-testimonials">
                  - Service desk have reduced downtime for real-time and
                  relevant information discovery.
                </li>
              </ul>
              <ul className="float-left w-100 blogs_ul_upd pl-4 mb-1">
                <li className="font-section-sub-header-small color-black">
                  Accessibility
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18 pl-5">
                The service desk eventually improves its performance with GenAI
                chatbots.
              </p>
              <ul className="float-left w-100 blogs_ul_upd pl-6 list-style-type-none">
                <li className="font-section-normal-text-testimonials">
                  - Users can get LLM-powered knowledge searches for queries
                  round-the-clock.
                </li>
                <li className="font-section-normal-text-testimonials">
                  - Employees can enjoy faster resolution time for consistent
                  service desk communications.
                </li>
              </ul>
              <ul className="float-left w-100 blogs_ul_upd pl-4 mb-1">
                <li className="font-section-sub-header-small color-black">
                  Scalability
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18 pl-5">
                The service desk can elevate its capacity using GenAI
                properties.
              </p>
              <ul className="float-left w-100 blogs_ul_upd pl-6 list-style-type-none">
                <li className="font-section-normal-text-testimonials">
                  - GenAI chatbot allows your service desk agents to handle
                  multiple tickets simultaneously, providing an elevated
                  experience.
                </li>
                <li className="font-section-normal-text-testimonials">
                  - Even during peak seasons, GenAI chatbots can meet growing
                  demands.
                </li>
              </ul>

              <CaseStudyCtaGoTo colorKey="peach" textColor="blue" />
            </div>
            <div className="divs_blogs_upt float-left" id="section6">
              <h2 class="font-section-sub-header-blog color-heading-blogs">
                Boost user experience for IT support with the Workativ GenAI
                chatbot
              </h2>
              <p class="font-section-normal-text-testimonials line-height-18">
                Automating common IT issues is always desirable to give
                employees a steadfast way to work, free agents to focus on
                creative work, and elevate the service desk experience.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                Workativ makes it easy to implement GenAI capacity within
                conversational AI chatbots and gain automation to an exception
                capacity to streamline repetitive tasks, help employees solve
                their common IT problems, and empower agents to handle more
                tickets while enjoying free time while increasing efficiency and
                productivity.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Workativ allows Knowledge AI to easily build chatbots with GenAI
                properties and allow ChatGPT-like user experiences.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                To build your GenAI chatbot and handle common IT issues
                autonomously,{" "}
                <a href="https://workativ.com/conversational-ai-platform/demo">
                  schedule a demo today.
                </a>
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section7">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                FAQs
              </h2>
              <h3 className="font-section-sub-header-small">
                1. What is the GenAI chatbot for employee support?
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                A GenAI chatbot for employee support has generative artificial
                intelligence properties that automate common IT support use
                cases, improving communications and collaborations and bringing
                a zero-touch experience for your employees.
              </p>
              <h3 className="font-section-sub-header-small">
                2. How do you define GenAI chatbot vs. traditional chatbot for
                your employee support?
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                A GenAI chatbot for employee support has unique content
                generation and NLP processing abilities, enabling it to produce
                custom responses and solve problems efficiently.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                On the other hand, typical self-service chatbots depend on
                predefined templates, which can become exhausted at any point
                when a query is outside the known scenarios.
              </p>
              <h3 className="font-section-sub-header-small">
                3. How can the GenAI chatbot be used for employee support
                automation?
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                GenAI chatbots help streamline workflows, automate mundane and
                repetitive tasks, and resolve problems autonomously without
                human intervention. Some common use cases for employee IT
                support encompass password reset, account unlock, software
                provisions, etc.
              </p>
              <h3 className="font-section-sub-header-small">
                4. What is the fastest and easiest way to build your GenAI
                chatbot for employee IT support?
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                Workativ provides a SaaS-based chatbot builder with zero coding
                experience. With the built-in Knowledge AI, you can easily
                enable Generative AI capabilities, upload your articles, turn
                them into ChatGPT-like generative answers for user queries, and
                build your workflows or leverage custom responses to automate
                your employee support. To learn more about Workativ,{" "}
                <a href="https://workativ.com/contact-us">
                  get in touch with us.
                </a>
              </p>
            </div>
            <BlogCta
              ContentCta="Auto-resolve 60% of Your Employee Queries With Generative AI Chatbot & Automation."
              ButtonText="Book a Demo"
              isColor="black"
              backgroundImage={cta_1}
              mobileBackgroundImage={cta_1_mob}
            />
            <AuthorBio />
            <RelatedBlogs />
          </div>
        </div>
      </div>
    </section>
  );
}
function ContentContainerMobile() {
  const [activeLink, setActiveLink] = useState("");
  const handleClick = (section) => {
    setActiveLink(section);
  };

  const [tableoFContentsDisplay, settableoFContentsDisplay] = React.useState(
    "hide"
  );
  const onArrowClick = () => {
    if (tableoFContentsDisplay === "show") {
      settableoFContentsDisplay("hide");
    } else {
      settableoFContentsDisplay("show");
    }
  };

  return (
    <section className="w-100 float-left mb-3 mt-4">
      <div className="container">
        <div className="grid_container_goto_second grid_blogs_container_mobile">
          <div className="grid-left-container-goto">
            <div
              className="mobile_accordian_title_goto_case"
              onClick={onArrowClick}
            >
              <h2 className="font-section-sub-header-small mb-3">
                Table of Contents
              </h2>
              {tableoFContentsDisplay === "show" ? (
                <img
                  className="arrow_goto_case_study_mobile mb-3"
                  src={arrow_close}
                ></img>
              ) : (
                <img
                  className="arrow_goto_case_study_mobile mb-3"
                  src={arrow_open}
                ></img>
              )}
            </div>
            {tableoFContentsDisplay === "show" ? (
              <div className="mobile_accordian_content_goto_case mt-4">
                <div className="redirection-flex-container">
                  <AnchorLink
                    offset={180}
                    href="#section1"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section1"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section1")}
                  >
                    1. What is a GenAI chatbot for your employee support?
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section2"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section2"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section2")}
                  >
                    2. Why is a traditional service desk a pain for everyone?
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section3"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section3"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section3")}
                  >
                    3. How does a GenAI chatbot address existing service desk
                    challenges for employee support?
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section4"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section4"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section4")}
                  >
                    4. What Common IT use cases can you implement with a GenAI
                    chatbot?
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section5"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section5"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section5")}
                  >
                    5. Benefits of GenAI bot for your employee support
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section6"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section6"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section6")}
                  >
                    6. Boost user experience for IT support with the Workativ
                    GenAI chatbot
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section7"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section7"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section7")}
                  >
                    7. FAQs
                  </AnchorLink>
                </div>
              </div>
            ) : null}
          </div>
          <div className="grid-right-container-goto">
            <div className="divs_blogs_upt">
              <p className="font-section-normal-text-testimonials line-height-18">
                Companies see great convenience in using chatbots for repetitive
                tasks around employee support.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                However, the problem arises when chatbots exhibit limited
                capacity to handle even common employee IT conversations and
                offer very little help for real-time solutions to employees.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Enter the GenAI chatbot. GenAI or LLM-powered solutions are
                making waves for their exceptional ability to{" "}
                <span className="font-section-normal-text-testimonials-medium color-black">
                  boost employee productivity.
                </span>
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                In its article, the{" "}
                <a href="https://www.cio.com/article/1312721/3-areas-where-gen-ai-improves-productivity-until-its-limits-are-exceeded.html">
                  CIO
                </a>{" "}
                said that EY heavily uses GenAI to automate internal tasks for
                its 40,000-plus employees, and they see a huge ROI on their AI
                investments.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Best known for creating new content, processing massive amounts
                of data, and producing human-like responses, the best
                application of GenAI is to implement its properties to
                complement chatbots.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                The LLM revolution helps democratize AI for everyone. SMBs can
                get an edge and take employee support to the next level with
                GenAI chatbots.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Lest you stress over how to handle common employee IT issues,
                let GenAI bot intervene.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section1">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                What is a GenAI chatbot for your employee support?
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18">
                A GenAI chatbot is an employee support chatbot that employs
                GenAI properties to automate communications and collaborations,
                automate information delivery for problem resolutions of common
                and cross-functional issues, improve agents’ efficiency, and
                enhance user experience.
              </p>
              <h3 className="font-section-sub-header-small">
                Onboarding Experience: Traditional vs. GenAI Chatbot
              </h3>
              <img
                src={section_1}
                className="blog_image_top_bt"
                alt="Generative AI chatbot vs. traditional chatbots for onboarding experience"
              />
              <p className="font-section-normal-text-testimonials line-height-18">
                For example, a new hiring onboarding process is underway. You
                know the internal tasks for HR departments and their related
                cross-functional operations.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                A new hire onboarding process includes completing paperwork,
                setting up a user email, adding a user to the internal
                communications channel, allowing provisions for company-wide
                tools, etc.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                These are common yet repetitive tasks for both IT and HR teams.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                GenAI chatbot uses large language models and automates these
                tasks easily, which helps make the user experience amazing and
                unforgettable.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section2">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                Why is a traditional service desk a pain for everyone?
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18">
                A service desk is always challenging for agents, especially when
                you fear agent burnout.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                A service desk is often not modern, meaning it still has a
                typical chatbot for employees to address their day-to-day
                issues.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                As a result, it triggers many unforeseen problems for employees,
                agents, stakeholders, etc. Eventually, this costs companies lost
                productivity, a slump in profit, and reduced user experience.
              </p>
              <img
                src={section_2}
                className="blog_image_top_bt"
                alt="Generative AI automation to remove IT service desk challenges"
              />
              <p className="font-section-normal-text-testimonials line-height-18">
                Some glaring problems are—
              </p>
              <ul className="float-left w-100 blogs_ul_upd pl-4 mb-1">
                <li className="font-section-sub-header-small color-black">
                  Repetitive issues
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18 pl-5">
                <a href="https://workativ.com/conversational-ai-platform/blog/reset-password-requests-5-star-rating-with-workativ-assistant">
                  Password resets,
                </a>{" "}
                account unlocks, and software installs are common yet repetitive
                problems. Limited automation for knowledge discovery forces
                employees to escalate tickets to agents.
              </p>
              <ul className="float-left w-100 blogs_ul_upd pl-4 mb-1">
                <li className="font-section-sub-header-small color-black">
                  Rising ticket volumes
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18 pl-5">
                The number of tickets depends on the number of tools a business
                uses. On average, a service desk may receive 20-25 tickets, and
                a ticket may take a few hours to resolve, while a complex may
                take a few days for resolution.
              </p>
              <ul className="float-left w-100 blogs_ul_upd pl-4 mb-1">
                <li className="font-section-sub-header-small color-black">
                  Fragmented system for service desk management
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18 pl-5">
                Agents become frustrated as they need to toggle between emails,
                voice calls, and chats to trace tickets and provide a solution.
                The unresolved tickets add to the queue, resulting in a ticket
                backlog.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section3">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                How does a GenAI chatbot address existing service desk
                challenges for employee support?
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18">
                Generative AI trains on massive datasets and thousands of
                examples of real-world functions, enabling the AI model to build
                semantic and intent search abilities.
              </p>
              <img
                src={section_3}
                className="blog_image_top_bt"
                alt=" GenAI bot for service desk automation"
              />
              <p className="font-section-normal-text-testimonials line-height-18">
                Below are the expected possibilities with GenAI models—
              </p>
              <ul className="float-left w-100 blogs_ul_upd font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  Learning new content using existing examples
                </li>
                <li className="font-section-normal-text-testimonials">
                  Adaptive learning capacity with changing scenarios
                </li>
                <li className="font-section-normal-text-testimonials">
                  Suggesting contextual answers
                </li>
                <li className="font-section-normal-text-testimonials">
                  Less probability of being overwhelmed while interacting with
                  massive datasets
                </li>
              </ul>

              <p className="font-section-normal-text-testimonials line-height-18">
                With these abilities, GenAI solutions stay ahead of
                keyword-based chatbots and act more like{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/ai-co-pilot-it-operations">
                  AI copilots for IT leaders,
                </a>{" "}
                service desk agents, and users.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Let’s see how GenAI chatbots work better than regular chatbots:
              </p>
              <img
                src={section_4}
                className="blog_image_top_bt"
                alt="GenAI bot vs. typical chatbots for service desk automation"
              />
              <h3 className="font-section-sub-header-small">
                Custom responses
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                Typical chatbots: Any search inputs work by matching queries
                with keywords or existing pre-defined chat templates or option
                cards. Employees who want answers outside the predefined
                structures can only expect rudimentary answers.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                GenAI chatbots: LLMs help GenAI chatbots run semantic or
                intent-based search functions. Whether a question is prepared
                for service desk scenarios, the GenAI chatbot handles it
                intelligently and escalates it to an agent if no proper response
                is provided.
              </p>
              <h3 className="font-section-sub-header-small">User adoption</h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                Typical chatbots: Users usually avoid using typical chatbots as
                they often find them unresponsive or difficult to use. They
                prefer using emails, phones, chats, and even personal devices to
                communicate their IT issues.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                GenAI chatbots: Knowledge articles or third-party company data
                easily provide rich resources for its LLM databases. Users can
                easily find what they need to work. This improves adoption and
                problem resolution.
              </p>
              <h3 className="font-section-sub-header-small">
                Reduced ticket counts
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                Typical chatbots: More than often, knowledge articles remain
                static, offering outdated information. FAQs are also limited.
                This leads employees to seek help from service desk agents,
                increasing the ticket numbers.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                GenAI chatbots: LLMs make it easy to keep knowledge bases
                updated with their inherent capacity to learn new things.
                Without the need to add new scenarios to the KBs, GenAI chatbots
                can help employees solve common problems with updated
                information, reducing ticket escalation.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Thus, A GenAI chatbot can provide several and many more useful
                use cases for your employee support and make your IT support
                efficient and effective, ensuring business resilience and
                operations efficiency.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section4">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                What Common IT use cases can you implement with a GenAI chatbot?
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18">
                GenAI chatbots can easily turn into an AI copilot. As their
                assistants, it can help your employees handle repetitive tasks
                and reduce their workload. GenAI chatbot or AI copilot makes
                performing a task easier, which increases their productivity in
                many ways.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                GenAI chatbot use cases for common IT employee issues—
              </p>
              <h3 className="font-section-sub-header-small">
                1. Reminder for maintenance work
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                One important IT task is maintaining company applications. By
                creating a prompt or GenAI workflow for conversational AI, your
                IT employees can do their jobs and never miss important work to
                maintain operational efficiency.
              </p>
              <img
                src={section_5}
                className="blog_image_top_bt"
                alt="GenAI bot for non-stop productivity"
              />
              <h3 className="font-section-sub-header-small">
                2. Request triage
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                It is usual to have hundreds of common IT queries for your
                employees. But, service desk agents struggle to decide who needs
                to take care of a particular issue. GenAI chatbot can
                efficiently solve common queries and detect when a request needs
                expert help. It can instantly route the request to the right
                team.
              </p>
              <img
                src={section_6}
                className="blog_image_top_bt"
                alt="GenAI bot for triage automation"
              />
              <h3 className="font-section-sub-header-small">
                3. Troubleshoot common IT issues
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                In most scenarios, employees do not get their IT questions
                answered. LLMs help turn knowledge into ChatGPT-like responses
                for user queries and troubleshoot common IT issues.
              </p>
              <img
                src={section_7}
                className="blog_image_top_bt"
                alt="GenAI bot for troubleshooting common issues"
              />
              <h3 className="font-section-sub-header-small">
                4. Check password expiration
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                Password expiration can impact employees' productivity if not
                done on time. By creating an automated workflow for password
                expiration, you can allow your employees to quickly get the
                alert and create new passwords for continuous access.
              </p>
              <img
                src={section_8}
                className="blog_image_top_bt"
                alt="GenAI bot for notification automation for password reset"
              />
              <h3 className="font-section-sub-header-small">
                5. Update profile information
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                Your employees must have access to company-wide applications.
                GenAI bot can use workflows that allow your employees to provide
                their information and keep having access to them.
              </p>
              <img
                src={section_9}
                className="blog_image_top_bt"
                alt="GenAI bot for profile updates"
              />
              <h3 className="font-section-sub-header-small">
                6. Request app removal
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                You can send a workflow to remove apps that are no longer
                needed. Users can easily fetch the necessary information from a
                GenAI chatbot and help your employees remove an app.
              </p>
              <h3 className="font-section-sub-header-small">
                7. Account unlock
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                Employees getting locked out of key applications is common and
                creates productivity issues. GenAI helps you create many options
                for your employees to try and get access to accounts.
              </p>
              <img
                src={section_10}
                className="blog_image_top_bt"
                alt="GenAI bot for account unlock automation"
              />
              <h3 className="font-section-sub-header-small">
                8. Software provision
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                You don’t know when your people need a software suite to
                complete their work. Instead of chasing teammates to learn how
                to request a tool or connect with the IT team, your employees
                can access the necessary information and get help instantly.
              </p>
              <img
                src={section_11}
                className="blog_image_top_bt"
                alt="GenAI bot for automating software provisions"
              />
              <h3 className="font-section-sub-header-small">
                9. Password resets
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                Employees often face account access issues and need help with
                ‘reset passwords’ requests. GenAI chatbot dramatically reduces
                ticket escalation to service desk agents and offers real-time
                help to reset passwords.
              </p>
              <img
                src={section_12}
                className="blog_image_top_bt"
                alt="GenAI bot for password reset automation"
              />
              <h3 className="font-section-sub-header-small">
                10. Approval management
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                Most applications are not easily accessible, and your employees
                need manager approvals. GenAI bots can surface workflows that
                allow for easy approvals and increase productivity.
              </p>
              <img
                src={section_13}
                className="blog_image_top_bt"
                alt="GenAI bot for approval management automation"
              />
              <BlogCta
                ContentCta="Optimize Your IT Service Management with AI."
                ButtonText="Book a Demo"
                isColor="white"
                backgroundImage={cta_2}
                mobileBackgroundImage={cta_2_mob}
              />
            </div>
            <div className="divs_blogs_upt float-left" id="section5">
              <h2 class="font-section-sub-header-blog color-heading-blogs">
                Benefits of GenAI bot for your employee support
              </h2>
              <p class="font-section-normal-text-testimonials line-height-18">
                When you leverage GenAI for your conversational AI chatbots, you
                can create an exceptional user experience by enabling them to do
                their best.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                Below are some essential GenAI chatbot benefits for your
                employee support—
              </p>
              <ul className="float-left w-100 blogs_ul_upd pl-4 mb-1">
                <li className="font-section-sub-header-small color-black">
                  Service desk efficiency
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18 pl-5">
                Service desks have increased satisfaction for increased
                efficiency.
              </p>
              <ul className="float-left w-100 blogs_ul_upd pl-6 list-style-type-none">
                <li className="font-section-normal-text-testimonials">
                  - Users can get instant solutions for common IT issues.
                </li>
                <li className="font-section-normal-text-testimonials">
                  - Service desk have reduced downtime for real-time and
                  relevant information discovery.
                </li>
              </ul>
              <ul className="float-left w-100 blogs_ul_upd pl-4 mb-1">
                <li className="font-section-sub-header-small color-black">
                  Accessibility
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18 pl-5">
                The service desk eventually improves its performance with GenAI
                chatbots.
              </p>
              <ul className="float-left w-100 blogs_ul_upd pl-6 list-style-type-none">
                <li className="font-section-normal-text-testimonials">
                  - Users can get LLM-powered knowledge searches for queries
                  round-the-clock.
                </li>
                <li className="font-section-normal-text-testimonials">
                  - Employees can enjoy faster resolution time for consistent
                  service desk communications.
                </li>
              </ul>
              <ul className="float-left w-100 blogs_ul_upd pl-4 mb-1">
                <li className="font-section-sub-header-small color-black">
                  Scalability
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18 pl-5">
                The service desk can elevate its capacity using GenAI
                properties.
              </p>
              <ul className="float-left w-100 blogs_ul_upd pl-6 list-style-type-none">
                <li className="font-section-normal-text-testimonials">
                  - GenAI chatbot allows your service desk agents to handle
                  multiple tickets simultaneously, providing an elevated
                  experience.
                </li>
                <li className="font-section-normal-text-testimonials">
                  - Even during peak seasons, GenAI chatbots can meet growing
                  demands.
                </li>
              </ul>

              <CaseStudyCtaGoTo colorKey="peach" textColor="blue" />
            </div>
            <div className="divs_blogs_upt float-left" id="section6">
              <h2 class="font-section-sub-header-blog color-heading-blogs">
                Boost user experience for IT support with the Workativ GenAI
                chatbot
              </h2>
              <p class="font-section-normal-text-testimonials line-height-18">
                Automating common IT issues is always desirable to give
                employees a steadfast way to work, free agents to focus on
                creative work, and elevate the service desk experience.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                Workativ makes it easy to implement GenAI capacity within
                conversational AI chatbots and gain automation to an exception
                capacity to streamline repetitive tasks, help employees solve
                their common IT problems, and empower agents to handle more
                tickets while enjoying free time while increasing efficiency and
                productivity.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Workativ allows Knowledge AI to easily build chatbots with GenAI
                properties and allow ChatGPT-like user experiences.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                To build your GenAI chatbot and handle common IT issues
                autonomously,{" "}
                <a href="https://workativ.com/conversational-ai-platform/demo">
                  schedule a demo today.
                </a>
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section7">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                FAQs
              </h2>
              <h3 className="font-section-sub-header-small">
                1. What is the GenAI chatbot for employee support?
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                A GenAI chatbot for employee support has generative artificial
                intelligence properties that automate common IT support use
                cases, improving communications and collaborations and bringing
                a zero-touch experience for your employees.
              </p>
              <h3 className="font-section-sub-header-small">
                2. How do you define GenAI chatbot vs. traditional chatbot for
                your employee support?
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                A GenAI chatbot for employee support has unique content
                generation and NLP processing abilities, enabling it to produce
                custom responses and solve problems efficiently.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                On the other hand, typical self-service chatbots depend on
                predefined templates, which can become exhausted at any point
                when a query is outside the known scenarios.
              </p>
              <h3 className="font-section-sub-header-small">
                3. How can the GenAI chatbot be used for employee support
                automation?
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                GenAI chatbots help streamline workflows, automate mundane and
                repetitive tasks, and resolve problems autonomously without
                human intervention. Some common use cases for employee IT
                support encompass password reset, account unlock, software
                provisions, etc.
              </p>
              <h3 className="font-section-sub-header-small">
                4. What is the fastest and easiest way to build your GenAI
                chatbot for employee IT support?
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                Workativ provides a SaaS-based chatbot builder with zero coding
                experience. With the built-in Knowledge AI, you can easily
                enable Generative AI capabilities, upload your articles, turn
                them into ChatGPT-like generative answers for user queries, and
                build your workflows or leverage custom responses to automate
                your employee support. To learn more about Workativ,{" "}
                <a href="https://workativ.com/contact-us">
                  get in touch with us.
                </a>
              </p>
            </div>
            <BlogCta
              ContentCta="Auto-resolve 60% of Your Employee Queries With Generative AI Chatbot & Automation."
              ButtonText="Book a Demo"
              isColor="black"
              backgroundImage={cta_1}
              mobileBackgroundImage={cta_1_mob}
            />
          </div>
        </div>
      </div>
      <AuthorBio />
    </section>
  );
}
export const AuthorBio = () => {
  const isMobile = useMedia({ maxWidth: "520px" });
  return (
    <section className="w-100 float-left author_bio_parent_cont">
      <div className="author_bio_flex_container">
        {isMobile ? (
          <div className="author_bio_flex_left align-self-start">
            <img src={author_mobile} className="width-40"></img>

            <div className="align-self-center">
              <h2 className="font-section-sub-header-small-home mt-3">
                Deepa Majumder
              </h2>
              <h4 className="font-section-normal-text-testimonials">
                Content Writer
              </h4>{" "}
            </div>
          </div>
        ) : (
          <div className="author_bio_flex_left align-self-start">
            <img src={author}></img>
            <h2 className="font-section-sub-header-small-home mt-3">
              Deepa Majumder
            </h2>
            <h4 className="font-section-normal-text-testimonials">
              Content Writer
            </h4>{" "}
          </div>
        )}

        <div className="author_bio_flex_right w-100">
          <p className="font-section-normal-text-testimonials line-height-18">
            Deepa Majumder is a writer who nails the art of crafting bespoke
            thought leadership articles to help business leaders tap into rich
            insights in their journey of organization-wide digital
            transformation. Over the years, she has dedicatedly engaged herself
            in the process of continuous learning and development across
            business continuity management and organizational resilience.{" "}
          </p>
          <p className="font-section-normal-text-testimonials line-height-18 pb-0 mb-0">
            Her pieces intricately highlight the best ways to transform employee
            and customer experience. When not writing, she spends time on
            leisure activities.
          </p>
        </div>
      </div>
    </section>
  );
};
const RelatedBlogs = () => {
  const isMobile = useMedia({ maxWidth: "520px" });
  return (
    <div
      className={`most_popular_links ${isMobile ? "mt-4 container" : "mt-5"}`}
    >
      <div className="most_popular_header font-section-sub-header">
        Related Articles
      </div>
      <div className="most_popular_ul">
        <ul
          className="section__ul font-section-normal-text ul_related_blogs_upd"
          style={{ width: "100%", float: "left", borderBottom: "none" }}
        >
          <li>
            <a
              href="https://workativ.com/conversational-ai-platform/blog/top-itsm-trends-2023"
              className="font-section-normal-text-testimonials"
            >
              TOP ITSM Trends 2023: Preparing for the Future
            </a>
          </li>
          <li>
            <a
              href="https://workativ.com/conversational-ai-platform/blog/itsm-chatbot-guide"
              className="font-section-normal-text-testimonials"
            >
              ITSM CHATBOT- Use Cases and Benefits Explained
            </a>
          </li>
          <li>
            <a
              href="https://workativ.com/conversational-ai-platform/blog/service-desk-automation"
              className="font-section-normal-text-testimonials"
            >
              {" "}
              Service Desk Automation: ELEVATE YOUR IT SUPPORT EFFICIENCY
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};
